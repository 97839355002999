import loadGoogleMapsApi from 'load-google-maps-api';
import { mapStyle, mapOptions } from './constants';

const generateURL = (lat, lng) => {
  return `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`
}


export default class Maps {

  constructor() {

    this.markers = {};

    this.mapContainer = document.getElementById('map_canvas');
    if (this.mapContainer) {
      loadGoogleMapsApi({
        key: 'AIzaSyDKEYzcQghTMqpjRS7bX2P2Kkt2bew4z8U', //Need to function Maps
      }).then((google) => {
        this.google = google;
        this.googleIcon = {
          url: `${CCM_REL}/application/themes/publicislive/dist/images/marker.svg`, // url
          scaledSize: new google.Size(15, 23), // scaled size
          // origin: new google.Point(7.5, 23), // origin
          anchor: new google.Point(7.5, 11), // anchor
        };
        this.init();
      });
    }
  }

  handleLocationItem = (item) => {
    // console.log(item, index);
    if(!this.markers[item.id]){
      this.markers[item.id] = new this.google.Marker({
        position: new this.google.LatLng(item.lat, item.lng),
        map: this.map,
        animation: this.google.Animation.DROP,
        icon: this.googleIcon,
        title: item.name,
        url: item.url,
      });

      const openInfo = () => {
        this.infowindow.setContent(`<a href="${item.url}" target="_blank"><p style="margin: 0;color:#000; font-size: 12px;">${item.name}</p></a>`);
        this.infowindow.open(this.map, this.markers[item.id]);
        this.map.setCenter({ lat: item.lat, lng: item.lng });
      };
      //
      this.markers[item.id].addListener('click', openInfo);
      // $(`div[data-section-toggle="${item.id}"]`).on('click', openInfo);
    }

    setTimeout(() => {
      this.map.setCenter({
        lat : item.lat,
        lng : item.lng
      });
    }, 500);
  }

  init = () => {
    // this.myLatlng = new google.LatLng(25.2271895, 55.2866176);
    this.mapCenter = new this.google.LatLng(25.1888119, 55.295722);

    this.map = new this.google.Map(this.mapContainer, {
      ...mapOptions(this.google),
      ...{
        center: this.mapCenter,
        styles: mapStyle,
      },
    });

    this.infowindow = new this.google.InfoWindow({});
    // const locations = [
    //   {
    //     name: "PublicisLive, Dubai",
    //     id: 'dxb',
    //     lat: 25.188810,
    //     lng: 55.297963,
    //     url:'https://goo.gl/maps/MYLatZ8umkM7xBbY8'
    //   },
    //   {
    //     name: "PublicisLive, Paris",
    //     id: 'paris',
    //     lat: 48.8580316,
    //     lng: 2.3731988,
    //     url:'https://goo.gl/maps/D4HEcjnnpK7ngHUn7'
    //   },
    //   {
    //     name: "PublicisLive, Kigali",
    //     id: 'kigali',
    //     lat: -1.954572,
    //     lng: 30.093859,
    //     url:'https://goo.gl/maps/f2TXVRHgzvW2ZXT37'
    //   },
    //   {
    //     name: "PublicisLive, Istanbul",
    //     id: 'istanbul',
    //     lat: 41.085396,
    //     lng: 28.983424,
    //     url:'https://goo.gl/maps/XEotm6AE7SLGWpnk8'
    //   },
    //   {
    //     name: "PublicisLive, Geneva",
    //     id: 'geneva',
    //     lat: 46.202402,
    //     lng: 6.154112,
    //     url:'https://goo.gl/maps/K9iEN7Jmh6dgpkXe9'
    //   },
    //   {
    //     name: "PublicisLive, Abu Dhabi",
    //     id: 'auh',
    //     lat: 24.428331,
    //     lng: 54.463615,
    //     url:'https://goo.gl/maps/CMz3fP4W4E4dM6ns8'
    //   },
    // ];

    $(".location_set._ele").on('click',  (ev) =>
    {
      let locationId = $(ev.currentTarget).data('id');
      let newlat = parseFloat($(ev.currentTarget).data('lat'));
      let newlng = parseFloat($(ev.currentTarget).data('lng'));


      // newLocation(newlat,newlng);
      this.handleLocationItem({
        name: "PublicisLive",
        id: locationId,
        lat: newlat,
        lng: newlng,
        url: generateURL(newlat, newlng)
      })
    });

    setTimeout(function() {
      $(".location_set._ele:first-child ").trigger('click');
    },10);

    // $(".location_set._ele[data-id='paris']").on('click', function ()
    // {
    //   newLocation(48.8580316,2.3731988);
    // });
    // $(".location_set._ele[data-id='kigali']").on('click', function ()
    // {
    //   newLocation(-1.9545556,30.0916594);
    // });
    // $(".location_set._ele[data-id='dxb']").on('click', function ()
    // {
    //   newLocation(25.188810,55.297963);
    // });
    // $(".location_set._ele[data-id='istanbul']").on('click', function ()
    // {
    //   newLocation(41.085396,28.983424);
    // });
    // $(".location_set._ele[data-id='geneva']").on('click', function ()
    // {
    //   newLocation(46.202402,6.154112);
    // });



    var screenWidth = window.screen.width ;
    if (screenWidth < 600)
    {
      this.map.setZoom(14);
    }
    // this.bindEvents(google);

  };





  bindEvents = (google) => {
    // this.google.event.addListener(this.marker, 'click', this.toggleBounce);





  };



  toggleBounce = (markerClicked) => {
    if (markerClicked.getAnimation() != null) {
      markerClicked.setAnimation(null);
    } else {
      markerClicked.setAnimation(this.google.Animation.BOUNCE);
    }
  };
}
