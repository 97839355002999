import Maps from './components/Maps';

export default new (class App {
  constructor() {
    this.setDomMap();
    this.previousScroll = 0;

    // dom ready shorthand
    $(() => {
      this.domReady();
    });
  }
  domReady = () => {
    this.initComponents();
  };

  initComponents = () => {


    document.addEventListener('mapsection_load', () => {
      new Maps({
        mapContainer: this.mapContainer,
      });
    });
  };

  setDomMap = () => {
    this.window = $(window);
    this.html = $('html');
    this.htmlBody = $('body');

    this.mapContainer = $('#map_canvas');
    this.showLocations = $('#showLocations');

  };


})();